<template>
	<ul class="menu-nav">
		<template v-for="(data, index) in menuList">
			<template v-if="data.childrens.length">
				<li
					aria-haspopup="true"
					:key="index"
					id="submenu1"
					data-menu-toggle="hover"
					class="menu-item menu-item-submenu"
					:class="{
						'menu-item-open menu-item-open-active': isRouteActive(data.name),
					}"
				>
					<a href="#" class="menu-link menu-toggle">
						<span class="menu-svg-icon mr-4">
							<inline-svg :src="$assetURL(`media/custom-svg/${data.name}.svg`)" />
						</span>
						<span class="menu-text">{{ data.text }}</span>
						<i class="menu-arrow"></i>
					</a>
					<div class="menu-submenu">
						<span class="menu-arrow"></span>
						<ul class="menu-subnav">
							<router-link
								v-for="(submenu, submenu_index) in data.childrens"
								:key="`${index}-${submenu_index}`"
								:to="{ name: submenu.route, query: { t: new Date().getTime() } }"
								v-slot="{ href, navigate, isActive, isExactActive }"
							>
								<li
									aria-haspopup="true"
									data-menu-toggle="hover"
									class="menu-item"
									:class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
								>
									<a :href="href" class="menu-link" v-on:click="navigate">
										<i class="menu-bullet menu-bullet-dot">
											<span></span>
										</i>
										<span class="menu-text">{{ submenu.text }}</span>
									</a>
								</li>
							</router-link>
						</ul>
					</div>
				</li>
			</template>
			<template v-else>
				<router-link
					:key="index"
					:to="{ name: data.route, query: { t: new Date().getTime() } }"
					v-slot="{ href, navigate, isActive, isExactActive }"
				>
					<li
						aria-haspopup="true"
						data-menu-toggle="hover"
						class="menu-item"
						@click="routeLinkClick(data)"
						:class="{ 'menu-item-active': isRouteActive(data.name) }"
					>
						<a :href="href" class="menu-link" @click="navigate">
							<span class="menu-svg-icon mr-4">
								<!--begin::Svg Icon-->
								<inline-svg :src="$assetURL(`media/custom-svg/${data.name}.svg`)" />
								<!--end::Svg Icon-->
							</span>
							<span class="menu-text">{{ data.text }}</span>
						</a>
					</li>
				</router-link>
			</template>
		</template>
	</ul>
</template>

<script>
import { getMenuList } from "@/core/services/jwt.service";
import { isEmpty, remove } from "lodash";
export default {
	name: "KTMenu",
	data() {
		return {
			menuList: [],
		};
	},
	methods: {
		initMenu() {
			const menuList = getMenuList();
			remove(menuList, { name: "user" });
			remove(menuList, { name: "setting" });
			this.menuList = menuList;
		},
		hasActiveChildren(match) {
			return this.$route["path"].indexOf(match) !== -1;
		},
		isRouteActive(match) {
			if (isEmpty(this.$route.meta) === false) {
				return this.$route.meta.menuActive === match;
			}
			return false;
		},
		routeLinkClick() {
			const subMenu = document.getElementById("submenu1");

			if (subMenu.classList.contains("menu-item-open")) {
				subMenu.classList.remove("menu-item-open");
			}
		},
		isChildActive(row) {
			let isActive = false;
			for (let i = 0; i < row.length; i++) {
				isActive = this.isRouteActive(row[i].menu);
				if (isActive) {
					return isActive;
				}
			}
			return isActive;
		},
	},
	mounted() {
		this.initMenu();
	},
};
</script>
